import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Users from './Users';
import Locations from './Locations';
import UsersHeadCells from './UsersHeadCells';
import OrdersHeadCells from './OrdersHeadCells';
import Orders from './Orders';
import Clients from './Clients';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  users: Users,
  orders: Orders,
  clients: Clients,
  locations: Locations,
  usersHeadCells: UsersHeadCells,
  ordersHeadCells: OrdersHeadCells
});
