import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';
import App from './containers/App';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

export const store = configureStore();
const queryClient = new QueryClient()

const MainApp = () =>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </QueryClientProvider>
  </Provider>;


export default MainApp;
