import appLocaleData from 'react-intl/locale-data/pl';
import plMessages from '../locales/pl_PL.json';

const PlLang = {
  messages: {
    ...plMessages
  },
  locale: 'pl-PL',
  data: appLocaleData
};
export default PlLang;
