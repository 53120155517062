import {
  LOAD_ORDERS,
  SET_FILTER,
  REMOVE_ALL_FILTERS,
  DELETE_ORDER
} from "constants/ActionTypes";

const INIT_STATE = {
  orders: [],
  filters: [
    {
      id: "name",
      text: ""
    },
    {
      id: "surname",
      text: ""
    },
    {
      id: "email",
      text: ""
    },
    {
      id: "order_date",
      text: ""
    },
    {
      id: "order_id",
      text: ""
    },
    {
      id: "delivery_date",
      text: ""
    },
    {
      id: "delivery_location",
      text: ""
    },
    {
      id: "user_id",
      text: ""
    },
    {
      id: "status",
      text: ""
    }
  ]
};

const clearFilters = (filters) => {
  var clearedFilters=[]
  filters.forEach(filter => {
    clearedFilters.push({id: filter.id, text: ""});
  })
  return clearedFilters;
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_ORDERS: {
      return {
        ...state,
        orders: action.payload
      };
    }
    case DELETE_ORDER:{
      return{
        ...state,
        orders: state.orders.filter( order => order.id !== action.id )
      }
    }
    case SET_FILTER: {
      return {
        ...state,
        filters: state.filters.map(filter =>
          filter.id === action.id ? { ...filter, text: action.text } : filter
        )
      };
    }
    case REMOVE_ALL_FILTERS: {
      return {
        ...state,
        filters: clearFilters(state.filters)
      };
    }
    default:
      return state;
  }
};
