import {
  LOAD_USERS,
  SET_FILTER,
  SET_FILTER_ROLE,
  REMOVE_ALL_FILTERS,
  DELETE_USER
} from "constants/ActionTypes";

const INIT_STATE = {
  users: [],
  filters: [
    {
      id: "name_surname",
      text: ""
    },
    {
      id: "email",
      text: ""
    },
    {
      id: "location_id",
      text: ""
    }
  ],
  filtersRole: [
    {
      id: "boxA",
      check: true
    },
    {
      id: "boxZ",
      check: true
    }
  ]
};

const cleanFiltersRole = [
  {
    id: "boxA",
    check: true
  },
  {
    id: "boxZ",
    check: true
  }
];

const clearFilters = [
  {
    id: "name_surname",
    text: ""
  },
  {
    id: "email",
    text: ""
  },
  {
    id: "location_id",
    text: ""
  }
];

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_USERS: {
      return {
        ...state,
        users: action.payload
      };
    }
    case DELETE_USER:{
      return{
        ...state,
        users: state.users.filter( user => user.uid !== action.uid )
      }
    }
    case SET_FILTER: {
      return {
        ...state,
        filters: state.filters.map(filter =>
          filter.id === action.id ? { ...filter, text: action.text } : filter
        )
      };
    }
    case SET_FILTER_ROLE: {
      return {
        ...state,
        filtersRole: state.filtersRole.map(role =>
          role.id === action.id ? { ...role, check: !role.check } : role
        )
      };
    }
    case REMOVE_ALL_FILTERS: {
      return {
        ...state,
        filtersRole: cleanFiltersRole,
        filters: clearFilters
      };
    }
    default:
      return state;
  }
};
