import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import { firebaseAuth } from "../../firebase/firebase";
import { withRouter, useHistory } from "react-router-dom";

function UserInfo(props) {

  const [state, setState] = useState({
    anchorEl: null,
    open: false
  })

  let history = useHistory();

  const handleClick = event => {
    setState({ open: true, anchorEl: event.currentTarget });
  };

  const handleRequestClose = () => {
    setState({ open: false });
  };

    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt="..."
          src={
            firebaseAuth.currentUser != null ? firebaseAuth.currentUser.photoURL : ""
          }
          className="user-avatar "
        />
        <div className="user-detail">
          <h4 className="user-name" style={{ color: "#FFF"}} onClick={handleClick}>
            {firebaseAuth.currentUser != null
              ? firebaseAuth.currentUser.displayName
              : "Użytkownik"}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={state.anchorEl}
          open={state.open}
          onClose={handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          <MenuItem
            onClick={() => history.push("/app/settings")}
          >
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.settings" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleRequestClose();
              props.userSignOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default withRouter(connect(mapStateToProps, { userSignOut })(UserInfo));
