import React, {useEffect, useReducer} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import SignIn from './SignIn';
import {setInitUrl} from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { hideMessage } from "actions/Auth";



const App = (props) => {
  const {match, location, locale, authUser, initURL, isDirectionRTL, history} = props;
  const RestrictedRoute = ({component: Component, authUser, ...rest}) => {
    //console.log(authUser)
  return <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;
}
    const notificationReducer = (initialState, action) => {
      const message = action.payload;
      hideMessage();
      switch (action.type) {
        case "info":
          NotificationManager.info(message);
          break;
        case "success":
          NotificationManager.success(message);
          break;
        case "warning":
          NotificationManager.warning(message);
          break;
        case "error":
          NotificationManager.error(message);
          break;
        default:
          NotificationManager.info(message);
      }
    }
    const [state, dispatch] = useReducer(notificationReducer, null);
    useEffect(() => {
      window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
      if (initURL === '') {
        setInitUrl(history.location.pathname);
      }
  
    }, [])
    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/app/addOrder'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];

    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
              <NotificationDispatch.Provider value={dispatch}>
                <Switch>
                  <RestrictedRoute path={`${match.url}app`} authUser={authUser} component={MainApp}/>
                  <Route path='/signin' component={SignIn}/>
                  <Route
                    component={asyncComponent(() => import('components/Error404'))}/>
                </Switch>
                </NotificationDispatch.Provider>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
        <NotificationContainer />
      </MuiThemeProvider>
    );
}

const mapStateToProps = ({settings, auth}) => {
  const {sideNavColor, locale, isDirectionRTL} = settings;
  const {authUser, initURL} = auth;
  return {sideNavColor, locale, isDirectionRTL, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl})(App);
export const NotificationDispatch = React.createContext(null);