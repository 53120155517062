import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationDispatch } from "containers/App";
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  userSignIn
} from 'actions/Auth';

const SignIn = (props) => {

  const [state, setState] = useState({ email: '', password: '' })

  const mounted = useRef();

  const dispatchNotification = React.useContext(NotificationDispatch);
  const { email, password } = state;
  const { showMessage, loader, alertMessage, authUser, history, userSignIn, hideMessage, showAuthLoader } = props;

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (showMessage) {
        setTimeout(() => {
          hideMessage();
        }, 100);
      }
      if (authUser !== null) {
        history.push('/');
      }
    }
  })

    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="SOSApp">
              <p>Logo</p>
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Logowanie</h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email"/>}
                    fullWidth
                    onChange={(event) => setState({...state, email: event.target.value})}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password"/>}
                    fullWidth
                    onChange={(event) => setState({...state, password: event.target.value})}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        showAuthLoader();
                        userSignIn({ email, password });
                      }
                    }}
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button onClick={() => {
                      showAuthLoader();
                      userSignIn({email, password});
                    }} variant="contained" color="primary">
                      <IntlMessages id="appModule.signIn"/>
                    </Button>
                  </div>

                </fieldset>
              </form>
            </div>
          </div>

        </div>
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && dispatchNotification({type: 'error', payload: alertMessage})}
      </div>
    );
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader
})(SignIn);
