import { CHANGE_VISIBLE } from "constants/ActionTypes";

const INIT_STATE = [
  {
    id: "name_surname",
    numeric: false,
    disablePadding: true,
    label: "Imię i nazwisko",
    visible: true
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "E-mail",
    visible: true
  },
  {
    id: "location_id",
    numeric: false,
    disablePadding: true,
    label: "Lokalizacja",
    visible: true
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Rola",
    visible: true
  }
];

const UsersHeadCells = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VISIBLE:
      return state.map(headCell =>
        headCell.id === action.payload
          ? { ...headCell, visible: !headCell.visible }
          : headCell
      );
    default:
      return state;
  }
}

export default UsersHeadCells;
