import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { firebaseAuth } from "../firebase/firebase";
import { SIGNIN_USER, SIGNOUT_USER } from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  //tokenRefreshSuccess,
  userSignOutSuccess
} from "actions/Auth";
import LogRocket from 'logrocket';
//LogRocket.init('8i3pz5/piotr-wegrzyn');

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const getIdTokenResult = async () =>
  await firebaseAuth.currentUser
    .getIdTokenResult()
    .then(idTokenResult => idTokenResult)
    .catch(error => error);

const signOutRequest = async () =>
  await firebaseAuth
    .signOut()
    .then(authUser => authUser)
    .catch(error => error);

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem("user_id", signInUser.user.uid);
      const idTokenResult = yield call(getIdTokenResult);
      localStorage.setItem("user_role", idTokenResult.claims.admin);
      // LogRocket.identify(signInUser.user.uid, {
      //   name: signInUser.displayName,
      //   email: signInUser.email,
      // });
      yield put(
        userSignInSuccess({
          uid: signInUser.user.uid,
          role: idTokenResult.claims.admin,
          //isSuperadmin: idTokenResult.claims.superadmin,
        })
      );
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_role");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}
