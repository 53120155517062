import { CHANGE_VISIBLE } from "constants/ActionTypes";

const INIT_STATE = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Imię",
    visible: false
  },
  {
    id: "surname",
    numeric: false,
    disablePadding: true,
    label: "Nazwisko",
    visible: false
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "E-mail",
    visible: false
  },
  {
    id: "order_date",
    numeric: false,
    disablePadding: true,
    label: "Data zamówienia",
    visible: true
  },
  {
    id: "order_id",
    numeric: false,
    disablePadding: true,
    label: "Nr zamówienia",
    visible: true
  },
  {
    id: "delivery_date",
    numeric: false,
    disablePadding: true,
    label: "Data odbioru",
    visible: true
  },
  {
    id: "delivery_location",
    numeric: false,
    disablePadding: true,
    label: "Miejsce odbioru",
    visible: true
  },
  {
    id: "user_id",
    numeric: false,
    disablePadding: true,
    label: "Przyjmujący",
    visible: true
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status zamówienia",
    visible: true
  }
];

const OrdersHeadCells = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VISIBLE:
      return state.map(headCell =>
        headCell.id === action.payload
          ? { ...headCell, visible: !headCell.visible }
          : headCell
      );
    default:
      return state;
  }
}

export default OrdersHeadCells;
