import {
  LOAD_CLIENTS,
} from "constants/ActionTypes";

const INIT_STATE = {
clients: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case LOAD_CLIENTS: {
          return {
              ...state,
              clients: action.payload
          }
      }
      default:
          return state;
  }
}
