import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { connect } from 'react-redux';

class MenuPositions extends Component {

  render() {
    return (
      <ul className="nav-menu">
        <React.Fragment>
          <li className="menu no-arrow" style={{  display: "inline", float: "left"}}>
            <NavLink style={{ color: "#FFF"}} to="/app/addOrder">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="pages.addOrderPage" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow" style={{  display: "inline", float: "left"}}>
            <NavLink style={{ color: "#FFF"}} to="/app/ordersToDelivery">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="pages.ordersToDelivery" />{" "}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow" style={{  display: "inline", float: "left"}}>
              <NavLink style={{ color: "#FFF"}} to="/app/orders">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="pages.ordersPage" />{" "}
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow" style={{  display: "inline", float: "left"}}>
            <NavLink style={{ color: "#FFF"}} to="/app/archivedOrders">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="pages.archivedOrdersPage" />{" "}
              </span>
            </NavLink>
          </li>
        </React.Fragment>
        {this.props.auth.role && (
          <React.Fragment>
            <li className="menu no-arrow" style={{  display: "inline", float: "left"}}>
              <NavLink style={{ color: "#FFF"}} to="/app/products">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="pages.productsPage" />{" "}
                </span>
              </NavLink>
            </li>
            <li className="menu no-arrow" style={{  display: "inline", float: "left"}}>
              <NavLink style={{ color: "#FFF"}} to="/app/users">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="pages.usersPage" />{" "}
                </span>
              </NavLink>
            </li>
            {this.props.auth.role && this.props.auth.authUser == "QZBTiql4hbOBWg4tD1vlJF4nEDn1" &&
            <li className="menu no-arrow" style={{  display: "inline", float: "left"}}>
              <NavLink style={{ color: "#FFF"}} to="/app/administration">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="pages.administrationPage" />{" "}
                </span>
              </NavLink>
            </li>}
          </React.Fragment>
        )}
      </ul>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  return { auth };
};
export default withRouter(connect(mapStateToProps)(MenuPositions));